import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import globals from "../components/globals"
import ContentAndPicture from "../components/content-and-picture"
import { Container, FlexBox1, P, Li, H2 } from "../components/styledcomponents"
import { ContactFormFirma } from "../components/contactform"
import Button from "../components/button"
import SideBlocks from "../components/side-blocks"
import TrustBox from "../components/trusbox"

import Icons from "../components/icons"

const Icon = styled(Icons)`
  height: 0.8em;
  color: ${globals.color.main};
  position: relative;
  ${(props) => (props.left ? "bottom: 0.5em" : "top: 0.5em")};
  padding: 0 0.3em;
`

const Blockquote = styled.blockquote`
  max-width: ${globals.media.mobile};
  margin: auto;
`

const FirmenYoga = ({ data }) => (
  <Layout>
    <SEO
      title="Firmen-Yoga in München"
      description="Motivierte und zufriedene Mitarbeiter Dank Firmen-Yoga. Unsere Yogalehrer helfen Ihrem Buisness dank individuell auf Ihr Team abgestimmten Yogastunden."
    />
    <h1>Firmen-Yoga in München</h1>
    <Blockquote>
      <p>
        <Icon icon="quoteLeft" left />
        Der Erfolg eines Unternehmens kann am besten an der Gesundheit und dem
        Wohlergehen der Mitarbeiter gemessen werden.
        <Icon icon="quoteRight" />
      </p>
    </Blockquote>
    <p>
      Wie kann Ihr Unternehmen Erfolg durch{" "}
      <Link to="/yoga-muenchen/">Yoga</Link> Erreichen?
    </p>
    <ContentAndPicture
      h2="Yoga als Gesundheitsmanagement"
      description=<div>
        <P>
          Im Alltag verbringen wir fast alle einen großen Teil unserer Zeit
          unter hohem Leistungsdruck in der Firma an unserem Arbeitsplatz. Vor
          allem das lange Sitzen am Schreibtisch hat oft negative Konsequenzen
          auf unsere Gesundheit. Folgen sind zum Beispiel Nacken- und
          Rückenschmerzen, erhöhter Blutdruck, Chronische Schlafstörungen,
          Stoffwechsel-, Herz- und Kreislaufprobleme sowie Burnout (psychisch
          und physisch). Daher ist die Gestaltung des Arbeitsumfeldes so
          wichtig. Es soll die Gesundheit fördern und erhalten.
        </P>
        <P>
          <Link to="/yoga-muenchen/">Yoga</Link> ist zum Zweck des betrieblichen
          Gesundheitsmanagements eine gute Option. Yoga kann in der gewohnten
          Business-Kleidung im Büro praktiziert werden kann. Durch angemessene
          und einfache Yogaübungen können die Mitarbeiter ihren Körper gesund,
          entspannt, stabil und energiereich halten. Dann können sie den
          Herausforderungen des Alltags gestärkt begegnen.
        </P>
        <Button to="/firmen-yoga-in-muenchen/#angebot-anfordern">
          Firmenyoga Angebot anfordern
        </Button>
      </div>
      imgLeft={data.rueckenschmerzen}
      alt="Business Yoga in München bei TEam Laurien &amp; Alex - Ein Twist gegen Rückenschmerzen"
    />
    <ContentAndPicture
      h2="Der Aufbau einer Business Yogastunde"
      description=<div>
        <P>
          Wir stimmen die Yoga-Einheiten spezielle auf Ihr Business und ihr
          Teams ab. Eine Yogastunde dauert nach Absprache zwischen 30 und 60
          Minuten. Während der Einheit fördert Yoga Mobilität, Kraft, Balance
          und Achtsamkeit. Yoga stärkt die Muskulatur und verbessert die
          Haltung. Damit werden Verspannungen im ganzen Körper gelöst. Zudem
          förden Yogaübungen, vor allem durch den Fokus auf die Atmung, die
          Entspannung. Unsere Yogalehrer gehen gezielt auf die Bedürfnisse Ihres
          Teams ein.
        </P>
        <Button to="/firmen-yoga-in-muenchen/#angebot-anfordern">
          Firmenyoga Angebot anfordern
        </Button>
      </div>
      imgRight={data.balance}
      alt="Firmenyoga in München bei Team Laurien &amp; Alex- Balance, körperlich und geistig"
    />
    <ContentAndPicture
      h2="Positive Effekte für Ihre Mitarbeiter:"
      description=<div>
        <ul>
          <Li>Besseres Stressmanagement</Li>
          <Li>Mehr Energie</Li>
          <Li>Gestärkte Motivation</Li>
          <Li>Bessere Konzentration</Li>
          <Li>Erhöhte Effektivität und Effizienz</Li>
          <Li>Verbesserte Kreativität</Li>
          <Li>Größere Leistungsfähigkeit</Li>
          <Li>Teamgeist durch gemeinsames üben</Li>
        </ul>
        <H2>Positive Effekte für Ihr Unternehmen</H2>
        <ul>
          <Li>Verbesserte Konzentrationsfähigkeit der Mitarbeiter</Li>
          <Li> Erhöhte psychische und physische Belastbarkeit</Li>
          <Li> Reduzierte Ausfallzeiten und Krankheitskosten</Li>
          <Li> Steigerung der Produktivität</Li>
          <Li>Verbesserte Kommunikation</Li>
          <Li>Stärkerer Teamspirit</Li>
        </ul>
        <Button to="/firmen-yoga-in-muenchen/#angebot-anfordern">
          Firmenyoga Angebot anfordern
        </Button>
      </div>
      imgLeft={data.bewegung}
      alt="Firmenyoga in Muenchen bei TLA - Bewegung motiviert"
      columns="1fr 1fr"
    />
    <SideBlocks>
      Fördert Konzentration | Erhöht Mitarbeiterzufriedenheit | Reduziert Stress
      | Erhöht Produktivität
    </SideBlocks>
    <Container backgroundColor="transparent" padding="4em 0" />
    <TrustBox
      title="Unser Yoga- und Kampfsportangebot überzeugt:"
      values={[
        {
          id: 1,
          img: data.trust1,
          trust: "Kampfsportschule des Jahres 2020",
        },
        {
          id: 5,
          img: data.trust5,
          trust: "Yogalehrerausbildung in Indien von Lalit Kumar",
        },
        {
          id: 3,
          img: data.trust3,
          trust: "National und International erfolgreich",
        },
        {
          id: 6,
          img: data.trust6,
          trust: "BJJ Graduierung duch Cadu Francis",
        },
        {
          id: 4,
          img: data.trust4,
          trust: "Begeisterte Kunden",
        },
        {
          id: 2,
          img: data.trust2,
          trust: "IBJJF Zertifiziert",
        },
        {
          id: 7,
          img: data.trust7,
          trust: "Zertifiziert durch Kidding Around Yoga",
        },
      ]}
    />
    <FlexBox1 maxWidth={globals.media.tablet}>
      <P>
        Wir bieten unsere Yoga-Einheiten auch auf Englisch und Niederländisch
        an. Gerne stellen wir Ihnen unser Konzept ausführlich vor und passen
        unser Angebot an die individuellen Rahmenbedingungen Ihres Unternehmens
        an.
      </P>
    </FlexBox1>

    <ContactFormFirma />
  </Layout>
)

export default FirmenYoga

export const query = graphql`{
  rueckenschmerzen: file(
    relativePath: {eq: "Firmenyoga/Business-Yoga-in-Muenchen-gegen-Rueckenschmerzen-1-1024x717.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  balance: file(
    relativePath: {eq: "Firmenyoga/Firmenyoga-in-Muenchen-Balance-1024x695.jpeg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  bewegung: file(
    relativePath: {eq: "Firmenyoga/Firmenyoga-in-Muenchen-Bewegung-motiviert-1024x904.jpeg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust1: file(
    relativePath: {eq: "Kampfsportschule-des-Jahres-Team-Laurien-Alex.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust2: file(relativePath: {eq: "IBJJF-Zerifikat-TLA-BJJ.JPG"}) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust3: file(relativePath: {eq: "Deutsche-Meister-BJJ-IBJJF-Kickboxen.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust5: file(
    relativePath: {eq: "Yogalehrerzertifikat-in-indien-von-Lalit-Kumar-Himalaya-Yoga-Valley.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust4: file(relativePath: {eq: "Reviews-Team-Laurien-Alex.JPG"}) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust6: file(
    relativePath: {eq: "Laurien-and-alex-with-Cadu-Francis-purple-belt-graduation.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust7: file(relativePath: {eq: "Kinderyoga-Zertifikat.png"}) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
}
`
