import React from "react"
import styled from "styled-components"

import globals from "../components/globals"

const Rect = styled.div`
  background-color: ${globals.color.main};
  width: 40px;
  height: 100px;
`

const Text = styled.div`
  height: 100%;
  text-transform: uppercase;
`

function SideBlocks(props) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Rect></Rect>
      <Text>{props.children}</Text>
      <Rect></Rect>
    </div>
  )
}

export default SideBlocks
